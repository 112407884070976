import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '2c597129-4516-4928-8fe9-edccd04cf2a1',
    development: '9a8d8d59-70dc-444c-97e2-1f8eb43dbf08',
  },
}

export default {
  name: {
    nb: 'Transport og logistikk',
  },
  slug: 'transport',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-PX2VBVD',
  },
  assets: {
    logo: '/themes/transport/images/logo.svg',
    favicon: '/themes/transport/favicon.ico',
  },
  dbok: {
    nb: [
      {
        title: 'Transport, logistikk og løfteoperasjoner',
        link: 'https://reader.dbok.no/#/book/6059f44f669b1300117e09f2',
      },
      {
        title: 'Bransjelære',
        link: 'https://reader.dbok.no/#/book/605a02b1669b1300117e11a0',
      },
    ],
    nn: [
      {
        title: 'Transport, logistikk og løfteoperasjonar',
        link: 'https://reader.dbok.no/#/book/60993569484b110018849061',
      },
      {
        title: 'Bransjelære',
        link: 'https://reader.dbok.no/#/book/6090f622484b110018841e6b',
      },
    ],
  },
  styles: [
    '/themes/transport/css/transport.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Transport og logistikk vg2!</h1><p>Her finner du nettressurser for lærere og elever i programfagene «Transport, logistikk og løfteoperasjoner» og «Bransjelære».</p><p>De lysegrønne knappene med hengelås er ressurser for «Bransjeteknikk» og krever et eget abonnement.</p><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Transport og logistikk vg2!</h1><p>Her finn du nettressursar for lærarar og elevar i programfaga «Transport, logistikk og løfteoperasjonar» og «Bransjelære».</p><p>Dei lysegrøne knappane med hengelås er ressursar for «Bransjeteknikk. Desse krev eit eige abonnement.</p><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler'
      },
      {
        title: 'Grunnkunnskap',
        to: '/articles/grunnkunnskap',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Truck',
        to: '/articles/truck',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Løfteredskap',
        to: '/articles/lofteredskap',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'ADR-farlig gods',
        to: '/articles/adr-farlig-gods',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Klasse B – personbil',
        to: '/articles/klasse-b-personbil',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Nedlastbare filer',
        to: '/files/nedlastbare-filer',
      },
    ],
    nn: [
      {
        title: 'Omgrep',
        to: '/concepts/begreper',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler'
      },
      {
        title: 'Grunnkunnskap',
        to: '/articles/grunnkunnskap',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Truck',
        to: '/articles/truck',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Løftereiskap',
        to: '/articles/lofteredskap',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'ADR-farlig gods',
        to: '/articles/adr-farlig-gods',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Klasse B – personbil',
        to: '/articles/klasse-b-personbil',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Nedlastbare filer',
        to: '/files/nedlastbare-filer',
      },
    ],
  },
  eportalProducts: [
    {
      id: 'c4ae91a5-0294-4910-97ef-2841adea67d8',
      product: 'articles',
    },
  ],
}
